<template>
    <div>
        <a-form :form="form" @submit="handleSubmit">
            <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ paddingTop: 0, paddingBottom: '40px' }">
                <template #title>
                    <h5 class="mb-0">Guide details</h5>
                </template>
                <a-row :gutter="[24]">
                    <a-col :span="12" :lg="12" class="mb-12">
                        <a-col :span="24">
                            <a-form-item class="mb-10" label="Name" :colon="false">
                                <!-- @change="handleNameChanged" -->
                                <a-input v-model="guide.name"
                                    placeholder="eg. Barcelona Insider: Hidden Gems You Need to Know" />
                            </a-form-item>
                        </a-col>
                        <!-- <a-col :span="12">
              <a-form-item class="mb-10" label="Slug" :colon="false">
                <a-input v-if="isSlugInputDisabled && !isEdit" v-model="generatedSlugValue"
                  placeholder="Will be generated automatically" :disabled="isSlugInputDisabled"></a-input>
                <a-input v-else-if="isEdit" v-model="generatedSlugValue" placeholder="Will be generated automatically"
                  :disabled="isSlugInputDisabled" />
                <a-input v-else-if="!isSlugInputDisabled" v-model="generatedSlugValue"
                  placeholder="Will be generated automatically" :disabled="isSlugInputDisabled" />
              </a-form-item>
            </a-col> -->
                        <a-col :span="24">
                            <a-form-item class="mb-50" label="About" :colon="false">
                                <quill-editor v-model="quillHtmlConvContent" :options="quillOptions"
                                    style="height: 150px" @change="handleQuillChange" />
                            </a-form-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-item label="Guide's Media" :colon="false">
                                <a-upload :action="`${apiUrl}/upload/file`" list-type="picture-card"
                                    :file-list="fileList" @preview="handlePreview" @change="handleChange">
                                    <div v-if="fileList.length < 8">
                                        <a-icon type="plus" />
                                        <div class="ant-upload-text">Upload</div>
                                    </div>
                                </a-upload>
                                <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                                    <img alt="example" style="width: 100%" :src="previewImage" />
                                </a-modal>
                            </a-form-item>
                        </a-col>
                    </a-col>
                    <a-col :span="12" :lg="12" class="mb-12">
                        <a-col :span="12">
                            <a-form-item class="mb-10" label="Subheader" :colon="false">
                                <a-input v-model="guide.sub_header"
                                    placeholder="eg. Barcelona Insider: Hidden Gems You Need to Know" />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item class="mb-10" label="Type" :colon="false">
                                <a-input v-model="guide.type" placeholder="eg. Something" />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item class="mb-10" label="Premium" :colon="false">
                                <a-switch v-model="guide.premium" />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item class="mb-10" label="Public" :colon="false">
                                <a-switch v-model="guide.public" />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item class="mb-10" label="Author" :colon="false">
                                <a-select v-model="guide.author_id" style="width: 100%" placeholder="City">
                                    <a-select-option v-for="author in authors" :key="author.id" :value="author.id"
                                        selected>
                                        {{ author.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col v-if="isEdit" :span="12">
                            <a-form-item class="mb-10" label="Status" :colon="false">
                                <a-select v-model="guide.status" style="width: 100%">
                                    <a-select-option v-for="status in guide.statusToChoose" :key="status.id"
                                        :value="status.name" selected>
                                        {{ status.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item class="mb-10" label="SubCategory" :colon="false">
                                <a-select v-model="guide.sub_categories" mode="multiple" style="width: 100%"
                                    placeholder="SubCategory">
                                    <a-select-option v-for="subcategory in toChooseSubCategory" :key="subcategory.id"
                                        :value="subcategory.id" selected>
                                        {{ subcategory.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item class="mb-10" label="Highlights" :colon="false">
                                <a-select mode="tags" placeholder="eg. fast-food" v-model="guide.highlights" />
                            </a-form-item>
                        </a-col>
                        <a-col v-if="isEdit" :span="12">
                            <a-form-item class="mb-10" label="Status" :colon="false">
                                <a-switch v-model="guide.is_active" />
                            </a-form-item>
                        </a-col>
                    </a-col>
                </a-row>
                <a-row :gutter="[24]">
                    <a-col :span="12">
                        <h6>Content of the Guide</h6>
                        <draggable :list="guide.mentions" class="drag" ghost-class="ghost" @start="dragging = true"
                            @end="dragging = false">
                            <div class="drag-item" v-for="(element, index) in guide.mentions" :key="element.id">
                                <i class="pi pi-align-justify"></i>
                                <span>{{ element.title }} - [{{ element.type === "separator" ? "info block" : "mention"
                                    }}]</span>
                                <i class="pi pi-times" style="font-size: 1rem; cursor: pointer ;"
                                    @click="removeAt(index)"></i>
                            </div>
                        </draggable>
                        <a-form-item v-if="showChooseMention" class="mb-10" label="Choose Content" :colon="false">
                            <a-select v-model="mentionId" style="width: 100%" placeholder="Content"
                                @change="handleSelectedMention">
                                <a-select-option v-for="mention in toChooseMentions" :key="mention.id"
                                    :value="mention.id" selected>
                                    {{ mention.title }} - [{{ mention.type === "separator" ? "info block" : "mention"
                                    }}]
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-button style="margin-top: 20px;" @click="handleGuideContent">Add</a-button>
                    </a-col>
                </a-row>
                <!-- Error Message Display -->
                <div v-if="!isSlugInputDisabled" class="alert alert-warning" role="alert">
                    <p class="font-bold">Oops</p>
                    <p>Slug already exists. Please choose a different one.</p>
                </div>
            </a-card>
            <div style="display: flex; justify-content: space-between; width: 250px; margin-top: 20px;">
                <a-button type="primary" html-type="submit"> Submit </a-button>
                <a-button v-if="isEdit && !guide.is_reviewed" style="background-color: greenyellow;"
                    @click="handleGuideApprOrRej(true)">Approve</a-button>
                <a-button v-if="isEdit && !guide.is_reviewed" style="background-color: red; color: white;"
                    @click="handleGuideApprOrRej(false)">Reject</a-button>
            </div>
        </a-form>
    </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import router from "../../router/index.js";
import { quillEditor } from "vue-quill-editor";
import guideAPI from "../../api/guide.js";
import subcategoryAPI from "../../api/subcategory.js";
import mentionAPI from "../../api/mention.js";
import draggable from "vuedraggable";
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

export default {
    components: {
        quillEditor,
        draggable,
    },
    data() {
        return {
            quillHtmlConvContent: null,
            showChooseMention: false,
            apiUrl: process.env.VUE_APP_BASE_URL,
            mentionId: null,
            dragging: false,
            guide: {
                cover_image_id: null,
                is_reviewed: false,
                is_active: false,
                id: null,
                name: "",
                slug: "",
                sub_header: "",
                type: "",
                highlights: [],
                media: [],
                public: false,
                premium: false,
                author_id: "",
                status: "draft",
                sub_categories: [],
                mentions: [],
                statusToChoose: [
                    // draft, in_review, published, hidden, needs_redo
                    { id: 1, name: "draft" },
                    { id: 2, name: "in_review" },
                    { id: 3, name: "published" },
                    { id: 4, name: "hidden" },
                    { id: 5, name: "needs_redo" },
                ],
            },
            authors: [],
            toChooseSubCategory: [],
            toChooseMentions: [],
            quillContent: {},
            quillOptions: {
                modules: {
                    toolbar: [
                        [{ header: [1, 2, 3, false] }],
                        ["bold", "italic", "underline"],
                        [{ list: "ordered" }, { list: "bullet" }, "link"],
                        ["clean"],
                    ],
                },
            },
            guideEditData: null,
            nameChanged: false,
            isSlugInputDisabled: true,
            generatedSlugValue: "",
            isEdit: false,
            previewVisible: false,
            fileList: [],
            form: this.$form.createForm(this, { name: "editGuide" }),
            previewImage: null,
        };
    },
    methods: {
        handleQuillChange(content) {
            this.quillContent = content.quill.editor.delta;
        },
        handleGuideApprOrRej(status) {
            const mockEvent = {
                preventDefault: () => {
                    status ? console.log("approved") : console.log("rejected");
                }
            };

            if (status) {
                this.guide.is_reviewed = true;
                this.guide.status = 'published';
            } else {
                this.guide.is_reviewed = false;
                this.guide.status = 'needs_redo';
            }
            this.handleSubmit(mockEvent);
        },
        removeAt(index) {
            this.toChooseMentions.push(this.guide.mentions[index]);
            this.guide.mentions.splice(index, 1);
        },
        handleGuideContent() {
            this.showChooseMention = !this.showChooseMention;
        },
        handleSelectedMention() {
            const selectedMention = this.toChooseMentions.find(mention => mention.id === this.mentionId);
            this.guide.mentions.push(selectedMention);


            const index = this.toChooseMentions.findIndex(mention => mention.id === this.mentionId);
            if (index !== -1) {
                this.toChooseMentions.splice(index, 1);
            }

            this.mentionId = null;
            this.showChooseMention = false;
        },
        async getAuthors() {
            return authorAPI.getAuthors();
        },
        async getSubCategories() {
            return subcategoryAPI.getSubCategories();
        },
        async getMentions() {
            return mentionAPI.getMentions();
        },
        async getGuideBySlug(slug) {
            return guideAPI.getGuideBySlug(slug);
        },
        handleCancel() {
            this.previewVisible = false;
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
        async handlePreview(file) {
            if (!file.url && !file.preview) {
                file.preview = await this.getBase64(file.originFileObj);
            }
            this.previewImage = file.url || file.preview;
            this.previewVisible = true;
        },
        handleChange({ fileList }) {
            this.fileList = fileList;
        },
        onChangeClick(e) {
            console.log(e);
        },
        handleNameChanged() {
            this.nameChanged = true;
        },
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err) => {
                if (!err) {
                    for (let file of this.fileList) {
                        this.guide.media.push(file.response.data.id);
                    }
                    this.guide.slug = this.generatedSlugValue;
                    this.guide.about = this.quillContent;
                    if (this.guide.mentions.length === 0) {
                        this.guide.mentions = [];
                    } else {
                        this.guide.mentions = this.guide.mentions.map(mention => mention.id);
                    }
                    if (!this.isEdit) {
                        guideAPI
                            .createGuide(this.guide)
                            .then((responseData) => {
                                if (responseData.status == 200) {
                                    router.push("/guides/guides-list");
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                                if (error.code === 400) {
                                    this.isSlugInputDisabled = false;
                                }
                                this.guide.media = [];
                            });
                    } else {
                        guideAPI
                            .updateGuide(this.guide)
                            .then((responseData) => {
                                if (responseData.status == 200) {
                                    router.push("/guides/guides-list");
                                }
                            })
                            .catch((error) => {
                                if (error.code === 400) {
                                    this.isSlugInputDisabled = false;
                                }
                                this.guide.media = [];
                            });
                    }
                }
            });
        },
    },
    async mounted() {
        if (this.$route.params.id !== undefined) {
            this.guideEditData = await this.getGuideBySlug(this.$route.params.id); // get place data according to slug
            this.isEdit = true;
            this.guide.id = this.guideEditData.id;
            this.guide.name = this.guideEditData.name;
            this.guide.sub_header = this.guideEditData.sub_header;
            this.guide.type = this.guideEditData.type;
            this.generatedSlugValue = this.guideEditData.slug;
            this.guide.public = this.guideEditData.public;
            this.guide.premium = this.guideEditData.premium;
            this.guide.author_id = this.guideEditData.author.id;
            this.quillContent = this.guideEditData.about;
            this.guide.status = this.guideEditData.status;
            this.guide.is_active = this.guideEditData.is_active;
            if (this.guideEditData.cover_image) {
                this.guide.cover_image_id = this.guideEditData.cover_image.id;
            }
            // is reviewed 
            this.guide.is_reviewed = this.guideEditData.is_reviewed;

            this.guide.sub_categories = this.guideEditData.sub_categories.map(
                (subcategory) => subcategory.id
            );

            if (this.guideEditData.about != {} && this.guideEditData.about != null) {
                this.quillContent = this.guideEditData.about;
                var deltaOps = this.guideEditData.about.ops;
                var converter = new QuillDeltaToHtmlConverter(deltaOps, {});
                this.quillHtmlConvContent = converter.convert();
            }

            this.guide.mentions = this.guideEditData.mentions;
            this.guide.highlights = this.guideEditData.highlights;
            for (let i = 0; i < this.guideEditData.media.length; i++) {
                let media = this.guideEditData.media[i];
                let name = media.url.split("+")[media.url.split("+").length - 1];
                this.fileList.push({
                    uid: media.id,
                    response: {
                        data: {
                            id: media.id,
                        },
                    },
                    status: "done",
                    name: name,
                    url: "https://" + media.url,
                });
            }
        }
        const dataAuthors = await this.getAuthors();
        const dataSubCategories = await this.getSubCategories();
        const dataMentions = await this.getMentions();
        this.authors = dataAuthors.authors;
        this.toChooseSubCategory = dataSubCategories.sub_categories;
        // Filter out the mentions from dataMentions.Mentions where id is not present in this.guide.mentions
        const mentionsToCopy = dataMentions.mentions.filter(mention => {
            return !this.guide.mentions.some(guideMention => guideMention.id === mention.id);
        });
        mentionsToCopy.forEach(mention => {
            this.toChooseMentions.push(mention);
        });
    },
    /* computed: {
      generatedSlug() {
        return slug(this.guide.name);
      },
    },
    watch: {
      generatedSlug(newValue) {
        if (this.nameChanged) {
          this.generatedSlugValue = slug(newValue);
        }
      },
    }, */
}
</script>

<style lang="scss" scoped>
.product-img {
    border-radius: 12px;
}

.alert {
    font-weight: bold;
    background-color: #feebc8;
    border-left: 4px solid #f59e0b;
    color: #f59e0b;
    padding: 0.5rem;
}

.alert p.font-bold {
    font-weight: bold;
}

.drag {
    cursor: move;
}

.drag-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    cursor: move;
    padding: 7px;
    margin-top: 4px;
    margin-bottom: 4px;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
}

.ghost {
    cursor: move;
    visibility: hidden;
}
</style>
