import client from "./client";

const createSubCategory = (data) => {
  return client
    .post("/subcategory", data)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

const getSubCategory = (id) => {
  return client
    .get(`/subcategory/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => error.response);
};

const updateSubCategory = (id, data) => {
  return client
    .put(`/subcategory/${id}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

const getSubCategories = (filter) => {
  return client
    .get(`/subcategories`, filter)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => error.response);
};

const deleteSubCategories = (data) => {
  return client
    .put(`/subcategory`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};


const subcategoryAPI = {
  getSubCategories,
  createSubCategory,
  getSubCategory,
  updateSubCategory,
  deleteSubCategories,
};

export default subcategoryAPI;
